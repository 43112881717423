const colors = {
  'colors': {
    // layout
    // first western:
    // - blue: #3e5e6f
    // - khaki: #9aa184 
    // - gray: #7a7d81
    // 'primaryold:': '#e49f37',
    'primary': '#9aa184',
    'secondary': '#592203',
    'accent': '#ff6644',
    'background': 'white',
    'light': '#f7fafc',
    'dark': '#592203',
    'mutedBg': '#f1f1f1',
    // UI
    'text': '#181818',
    'textBody': '#585151',
    'textMuted': '#9da2a0', // #8b8a90
    'white': '#fff',
    'black': '#3e5e6f',
    'transparent': 'transparent',
    'gray': [
      null,
      '#f7fafc',
      '#edf2f7',
      '#e2e8f0',
      '#cbd5e0',
      '#a0aec0',
      '#718096',
      '#4a5568',
      '#2d3748',
      '#1a202c'
    ]
  }
}

export default colors
